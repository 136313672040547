
.login-page{
    background-color: rgb(220, 220, 220,0.5);
    background-image: linear-gradient(to top, #030930, #7c7e8a);
    background-image: linear-gradient(to bottom, #ffffff, #ace3cf);
    background-image: linear-gradient(to bottom, white, #823e8b);

    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-box{
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1.5rem 6rem;
 
    font-size: 1.2rem;
    border-radius: 10px;
    box-shadow: 1px 2px 2px 1px rgb(0, 0, 0,0.3);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.login-h{
    font-size: 2rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 1rem;
    font-style: italic;
}

.log-btn{
    width:90%;
    background-color: rgba(65, 100, 155, 0.7);

    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border: none;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    transition: 0.2s;
    margin: 0.5rem 0rem;
}

.login-logo{
  position: absolute;
  top: 0px;
  left: 10px;
  width: 70px;
  height: 70px;
}
.log-btn-g{

    transition: 0.2s;
    width:90%;
    background-color: rgb(0, 0, 0,0.7);
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border: none;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    margin: 0.5rem 0rem;

}

.lg-logo{
    font-size: 1.2rem;
    margin-bottom: -3px;
    margin-right: 5px;
}
.log-btn:hover, .log-btn-g:hover{
    cursor: pointer;
}

.chats-h{
    text-align: center;
    margin: 1rem;
    font-size: 2rem;
    color: black;
    font-style: italic;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}
body{
}
.lo-btn{
    position: absolute;
    font-size: 1.15rem;
    border: none;
    background-color: white;
    color: black;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1px solid black;
    right: 10px;
    top:10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: 0.3s;
}

.lo-btn:hover{
    cursor: pointer;
    background-color: black;
    color: white;
}

.log-btn:hover{
    background-color: rgb(66, 133, 244);
    background-color: rgba(65, 100, 155);

}

.log-btn-g:hover{
    background-color: rgb(0, 0, 0);
}









/* CHAT STYLES */
* {
    font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    letter-spacing: 0.5px;
  }
  
  .ce-chat-list {
    background-color: rgb(240, 240, 240) !important;
  }
  
  .ce-chats-container {
    background-color: rgb(240, 240, 240) !important;
  }
  
  .ce-active-chat-card {
    background-color: #9c8ab4 !important;
    border: 4px solid #9c8ab4 !important;
    border-radius: 0px !important;

  }
  
  .ce-chat-subtitle-text {
    color: #595959 !important;
  }
  
  .ce-chat-form-container {
    padding-bottom: 20px !important;
  }
  
  .ce-text-input {
    border-radius: 6px !important;
    border: 1px solid #3b2a50 !important;
  }
  
  .ce-primary-button {
    border-radius: 6px !important;
    background-color: #7554a0 !important;
    position: relative;
    bottom: 1px;
  }
  
  .ce-danger-button {
    background-color: white !important;
    border-radius: 22px !important;
  }
  
  .ce-settings {
    background-color: rgb(240, 240, 240) !important;
  }
  
  .ce-autocomplete-input {
    border-radius: 6px !important;
    border: 1px solid #3b2a50 !important;
  }
  
  .ce-autocomplete-options {
    border-radius: 6px !important;
    border: 1px solid #3b2a50 !important;
    background-color: white !important;
  }
  
  .ce-chat-settings-container {
    padding-top: 12px !important;
  }
  
  .ce-chat-avatars-row {
    display: none !important;
  }
  
  /* CUSTOM FEED */
  
  .chat-feed {
    height: 100%;
    width: 100%;
    overflow: scroll;
    background-color: rgb(240, 240, 240);
    background-color: #3b2a50;
  }
  
  .chat-title-container {
    width: calc(100% - 36px);
    padding: 18px;
    text-align: center;
  }
  
  .chat-title {
    color: #7554a0;
    font-weight: 800;
    font-size: 24px;
  }

  
  .chat-subtitle {
    color: #7554a0;
    font-weight: 600;
    font-size: 12px;
    padding-top: 4px;
  }
  
  .message-row {
    float: left;
    width: 100%;
    display: flex;
    margin-left: 18px;
  }
  
  .message-block {
    width: 100%;
    display: inline-block;
  }
  
  .message-avatar {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    color: white;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 48px;
  }
  
  .message {
    padding: 12px;
    font-size: 16px;
    border-radius: 6px;
    max-width: 60%;
  }
  
  .message-image {
    margin-right: 18px;
    object-fit: cover;
    border-radius: 6px;
    height: 30vw;
    /* width: 30vw; */
    max-height: 200px;
    max-width: 200px;
    min-height: 100px;
    min-width: 100px;
  }
  
  .read-receipts {
    position: relative;
    bottom: 6px;
  }
  
  .read-receipt {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    margin: 1.5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
  }
  
  .message-form-container {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 36px);
    padding: 18px;
    background-color: rgb(240, 240, 240);
  }
  
  .message-form {
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #3b2a50;
    background-color: white;
  }
  
  .message-input {
    height: 40px;
    width: calc(100% - 132px);
    background-color: white;
    border: 1px solid white;
    padding: 0px 18px;
    outline: none;
    font-size: 15px;
  }
  
  .ce-my-message-bubble{
    background-color: #9c8ab4 !important;
  }
  .ce-chat-title-text{
    color: #64507e !important;
  }
  .image-button {
    cursor: pointer;
    padding: 0px 12px;
    height: 100%;
  }
  
  .send-button {
    height: 42px;
    background-color: white;
    border: 1px solid white;
    padding: 0px 18px;
    cursor: pointer;
  }
  
  .send-icon {
    top: 1px;
    position: relative;
    transform: rotate(-90deg);
  }
  
  .picture-icon {
    top: 1px;
    position: relative;
    font-size: 14px;
  }
  
  /* FORM STYLES */
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62,5%;
  }
  
  .wrapper {
    height: 100vh;
      width: 100%;
    background: rgb(117,84,160);
    background: linear-gradient(90deg, rgba(117,84,160,1) 7%, rgba(117,84,160,1) 17%, rgba(106,95,168,1) 29%, rgba(99,103,174,1) 44%, rgba(87,116,184,1) 66%, rgba(70,135,198,1) 83%, rgba(44,163,219,1) 96%, rgba(22,188,237,1) 100%, rgba(0,212,255,1) 100%);  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .input {
    color: #333;
    font-size: 1.2rem;
      margin: 0 auto;
    padding: 1.5rem 2rem;
    border-radius: 0.2rem;
    background-color: rgb(255, 255, 255);
    border: none;
    width: 90%;
    display: block;
    border-bottom: 0.3rem solid transparent;
    transition: all 0.3s;
    outline: none;
    margin-bottom: 25px;
  }
  
  .form {
    width: 400px;
  }
  
  .title {
    text-align: center;
    color: white;
    margin-bottom: 30px;
    width: 100%;
    font-size: 2.3em;;
  }
  
  .button {
    border-radius: 4px;
    border: none;
    background-color: white;
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    padding: 20px;
    width: 200px;
    transition: all 0.4s;
    cursor: pointer;
    margin: 5px;
    width: 90%;
  }
  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
  }
  .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  .button:hover span {
    padding-right: 25px;
  }
  .button:hover span:after {
    opacity: 1;
    right: 0;
  }
  .chats-contain{
    width: 100vh;
    height: 100vw;
    background-color: black;
    color: white;
  }

  body{
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 600px) {
 .login-box{
   width: 90%;
   padding: 1.5rem 2rem;

 }
 .login-h{
   font-size: 5vw;

 }
.log-btn-g,.log-btn{
width: 95%;
}
}